<template>
  <CNavbarToggler
    aria-label="Toggle navigation"
    aria-expanded="{visible}"
    @click="visible = !visible"
    class="toggler"
  />
  <CButton @click="loginUser()" style="background-color: #3ca3ee">{{
    $t('TopMenuHome.Login')
  }}</CButton>
  <COffcanvas
    placement="start"
    :visible="visible"
    class="sidemenu"
    @hide="
      () => {
        visible = !visible
      }
    "
  >
    <COffcanvasHeader>
      <COffcanvasTitle>Menú</COffcanvasTitle>
      <CCloseButton
        class="text-reset"
        @click="
          () => {
            visible = false
          }
        "
      />
    </COffcanvasHeader>
    <COffcanvasBody>
      <CListGroup>
        <CListGroupItem
          ><CNavLink href="#">{{
            $t('TopMenuHome.Solutions')
          }}</CNavLink></CListGroupItem
        >
        <CListGroupItem
          ><CNavLink href="#">{{
            $t('TopMenuHome.About')
          }}</CNavLink></CListGroupItem
        >
        <CListGroupItem
          ><CNavLink href="#">{{
            $t('TopMenuHome.Prices')
          }}</CNavLink></CListGroupItem
        >
        <CListGroupItem
          ><CNavLink href="#">{{
            $t('TopMenuHome.Support')
          }}</CNavLink></CListGroupItem
        >
        <CListGroupItem
          ><CNavLink href="#">{{
            $t('TopMenuHome.Contact')
          }}</CNavLink></CListGroupItem
        >
        <CListGroupItem
          ><CNavLink href="#">{{
            $t('TopMenuHome.Developers')
          }}</CNavLink></CListGroupItem
        >
      </CListGroup>
    </COffcanvasBody>
  </COffcanvas>
</template>

<script>
export default {
  name: 'AppMainMenu2',
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import useAuth from '../hooks/useAuth'

const store = useStore()
let auth0Client = ref()
const baseURL = process.env.VUE_APP_BASE_URL
let { buildAuth0Client, login } = useAuth(baseURL, store)
let visible = ref(false)

onMounted(async () => {
  auth0Client.value = await buildAuth0Client()
  let userData = await auth0Client.value.getUser()
  store.commit('setUser', userData)
  localStorage.setItem('user', JSON.stringify(userData))
})

const loginUser = async () => {
  await login(auth0Client.value)
}
</script>

<style lang="scss" scoped>
@import '~@/styles/_variables.scss';
.menu-header {
  background-color: $copla-blue-grey-light;
}
.menu-header a {
  color: white;
}
.header {
  padding: 0;
}
.bggrey {
  background-color: $copla-grey-light;
}
.bgblue-grey-dark {
  background-color: $copla-blue-grey-dark;
}
.cblue {
  color: $copla-blue;
  font-weight: bold;
  font-size: 3.5em;
  fill: $copla-blue;
}
.intro {
  font-size: 2.5em;
  font-family: Poppins;
}
@media only screen and (max-width: 1400px) {
  .intro {
    font-size: 2.7em;
  }
  .cblue {
    font-size: 3.7em;
  }
  #lemma1 {
    transform: translateX(-70px) translateY(0px);
  }
  #lemma2 {
    transform: translateX(-70px) translateY(10px);
  }
  #lemma3 {
    transform: translateX(-70px) translateY(20px);
  }
}
@media only screen and (max-width: 991px) {
  .intro {
    font-size: 3em;
  }
  .cblue {
    font-size: 4em;
  }
  #lemma1 {
    transform: translateX(-70px) translateY(0px);
  }
  #lemma2 {
    transform: translateX(-70px) translateY(10px);
  }
  #lemma3 {
    transform: translateX(-70px) translateY(20px);
  }
}
@media only screen and (max-width: 767px) {
  .intro {
    font-size: 3.5em;
  }
  .cblue {
    font-size: 4.5em;
  }
  #lemma1 {
    transform: translateX(-70px) translateY(0px);
  }
  #lemma2 {
    transform: translateX(-70px) translateY(10px);
  }
  #lemma3 {
    transform: translateX(-70px) translateY(20px);
  }
  #logo {
    width: 600px;
    transform: translateX(-200px) translateY(-50px);
  }
}
@media only screen and (max-width: 575px) {
  .intro {
    font-size: 4em;
  }
  .cblue {
    font-size: 5em;
  }
  #lemma1 {
    transform: translateX(-70px) translateY(0px);
  }
  #lemma2 {
    transform: translateX(-70px) translateY(15px);
  }
  #lemma3 {
    transform: translateX(-70px) translateY(30px);
  }
  #logo {
    width: 600px;
    transform: translateX(-200px) translateY(-50px);
  }
}
.backgroundSVG {
  background-image: url('~@/assets/images/header.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.nav-link {
  color: $copla-blue-grey-dark !important;
}

.text-reset {
  background-color: $copla-grey-light;
}

.toggler {
  background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(219, 220,225, 1)' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}
</style>
