const config = {
  domain: 'aptent.eu.auth0.com',
  /*clientId: 'TZTnHFAzL19Xm7BZzOFuCo1WGCMAR7KE',*/
  clientId: 'afxWBgYqlVbiPVpaCRt3EhxLsDaXzwhn',
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: "https://aptent.eu.auth0.com/api/v2/",
    scope: "openid profile email"
  }
}

export default config
