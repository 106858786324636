import { defineComponent, h, onMounted, ref, resolveComponent } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import messages from '../lang'
import { useStore } from 'vuex'

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from '@coreui/vue'
import nav from '@/menus/copla.js' //Pasarle una prop a AppSideBarNav para que coja el _nav adecuado y las traducciones adecuadas

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, '')
    .replace(/(index)?\.(html)$/, '')

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false
  }

  if (route.hash === link) {
    return true
  }

  const currentPath = normalizePath(route.path)
  const targetPath = normalizePath(link)
  return currentPath === targetPath
}

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child))
  }

  return false
}

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const store = useStore()
    const { t } = useI18n({
      legacy: false,
      locale: 'es',
      fallbackLocale: 'es',
      globalInjection: true,
      messages,
    })
    const route = useRoute()
    const firstRender = ref(true)

    onMounted(() => {
      firstRender.value = false
    })

    const getMenu = () => {
      let data = {
        title: 'Menú',
        prefix: 'Main',
        import: nav,
      }
      return data
    }

    const menuType = getMenu()

    const renderItem = (item) => {
      // Aquí debería estar la comprobación de rol
      if ('roles' in item && item.roles.includes(store.state.rol.rol) && !item.status.includes(store.state.rol.status)) {
        let name = t(menuType.prefix + 'Menu.' + item.name)
        let linkActive = false
        if (normalizePath(route.path) == item.to) {
          linkActive = true
        }
        if (item.items) {
          return h(
            CNavGroup,
            {
              ...(firstRender.value && {
                visible: item.items.some((child) => isActiveItem(route, child)),
              }),
            },
            {
              togglerContent: () => [
                h(resolveComponent('CIcon'), {
                  customClassName: 'nav-icon',
                  name: item.icon,
                }),
                name,
              ],
              default: () => item.items.map((child) => renderItem(child)),
            },
          )
        }

        return item.to
          ? h(
              RouterLink,
              {
                to: item.to,
                custom: false,
              },
              {
                default: (props) =>
                  h(
                    resolveComponent(item.component),
                    {
                      active: linkActive,
                      href: props.href,
                      onClick: () => props.navigate(),
                    },
                    {
                      default: () => [
                        item.icon &&
                          h(resolveComponent('CIcon'), {
                            customClassName: 'nav-icon',
                            name: item.icon,
                          }),
                        name,
                        item.badge &&
                          h(
                            CBadge,
                            {
                              class: 'ms-auto',
                              color: item.badge.color,
                            },
                            {
                              default: () => item.badge.text,
                            },
                          ),
                      ],
                    },
                  ),
              },
            )
          : h(
              resolveComponent(item.component),
              {},
              {
                default: () => name,
              },
            )
      }
    }

    const title = h(
      'h2',
      { class: 'mt-4 mb-4 d-flex justify-content-center' },
      menuType.title,
    )

    const menu = h(
      CSidebarNav,
      {},
      {
        default: () => menuType.import.map((item) => renderItem(item)),
      },
    )

    const renderMenu = h('div', {}, title, menu)

    return () => renderMenu
  },
})
export { AppSidebarNav }
