import axios from 'axios'
import useAuth from './useAuth'

function useOrganization (baseURref, storeRef) {
  const baseURL = baseURref
  const store = storeRef
  const urlOrg = 'https://organization.' + baseURL + '/api/v1/organization'
  const urlRol = 'https://organization.' + baseURL + '/api/v1/rol'
  const urlSta = 'https://organization.' + baseURL + '/api/v1/status'

  async function getHeader () {
    const { getAccessToken } = useAuth(baseURL, store)
    const token = await getAccessToken()   
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return headers
  }

  async function getRoles () {
    try {
      const headers = await getHeader()
      const response = await axios.get(urlRol, headers)
      let arrayRoles = JSON.parse(response.data.data)
      localStorage.setItem('roles', response.data.data)
      arrayRoles.forEach(element => {
        store.commit('setRol', element)
      })
      return arrayRoles
    } catch (error) {
      if (error.response.data.code == 400) {
        let arrayRoles = []
        return arrayRoles
      }
      return error.response.data
    }
  }

  async function deleteRol (id) {
    try {
      const headers = await getHeader()
      const response = await axios.delete(urlRol + '/' + id, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function deleteOrganization (id) {
    try {
      const headers = await getHeader()
      const response = await axios.delete(urlOrg + '/' + id, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function updateLast (id) {
    try {
      const headers = await getHeader()
      const response = await axios.put(urlRol + '/' + id, {}, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function newRol (data) {
    try {
      const headers = await getHeader()
      const response = await axios.post(urlRol, data, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function changeRol (data) {
    try {
      const headers = await getHeader()
      const response = await axios.put(urlRol, data, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function getRolesOrganization (id) {
    try {
      const headers = await getHeader()
      const response = await axios.get(urlRol + '/organization/' + id, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function newOrganization (data) {
    try {
      const headers = await getHeader()
      const response = await axios.post(urlOrg, data, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function updateOrganization (id, data) {
    try {
      const headers = await getHeader()
      const response = await axios.put(urlOrg + '/' + id, data, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function getOrganization (id) {
    try {
      const headers = await getHeader()
      const response = await axios.get(urlOrg + '/' + id, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function putEmissionKey () {
    try {
      const headers = await getHeader()
      const response = await axios.put(
        urlOrg + '/key/' + store.state.rol.orgId,
        {},
        headers,
      )
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function getStatus () {
    try {
      const headers = await getHeader()
      if (
        store.state.rol.status !== '' &&
        store.state.rol.status !== undefined
      ) {
        const response = await axios.get(
          urlSta + '/' + store.state.rol.orgId,
          headers,
        )
        let arrayStatus = response.data.data
        store.state.rol.status = arrayStatus[arrayStatus.length - 1].value
        return arrayStatus
      }
    } catch (error) {
      return error.response.data
    }
  }

  async function setStatus (status) {
    try {
      const headers = await getHeader()
      let data = {
        _organizationId: store.state.rol.orgId,
        _status: status,
      }
      const response = await axios.post(urlSta, data, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  return {
    getRoles,
    deleteRol,
    deleteOrganization,
    updateLast,
    newOrganization,
    updateOrganization,
    getRolesOrganization,
    getOrganization,
    newRol,
    changeRol,
    putEmissionKey,
    getStatus,
    setStatus,
  }
}

export default useOrganization
